import React from "react"
import { motion } from "framer-motion"

const ColorChangingText = () => {
    const textVariants = {
        initial: { color: "#ffffff" },
        hover: { color: "#FF4500" }, // Oranžová farba jako příklad; můžete zvolit jakoukoli jinou
    }

    return (
        <motion.div
            initial="initial"
            whileHover="hover"
            animate="initial"
            style={{
                fontSize: "2rem",
                textAlign: "center",
                margin: "2rem",
                padding: "1rem",
                background: "#333", // Aby byl bílý text viditelný, nastavil jsem tmavé pozadí
                borderRadius: "8px",
                cursor: "pointer",
            }}
            variants={textVariants}
        >
            Design Studio Next Gen
        </motion.div>
    )
}

export default ColorChangingText
